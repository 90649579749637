export default {
  colors: {
    text: "white",
    background: "white",
    primary: "white",
    secondary: "white",
    accent: "white",
    modes: {
      dark: {
        text: "yellow",
        background: "blue",
        primary: "#f51505",
      },
    },
  },
}
